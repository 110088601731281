import { Dispatch } from 'redux';
import { Actions } from './actions';
import ImageDataSource from '../../types/ImageDataSource';

//  holdMap:Map<number, number>; // holdId -> imagecounts
export const addImageDataSource = (dispatch: Dispatch<any>, holdMap: Map<number, number>) => {
  let sourceMap: Map<number, Array<ImageDataSource>> = new Map<number, Array<ImageDataSource>>();
  holdMap.forEach( (imagecounts, holdId) => {
    let dataSource = [];
    for (let i=0; i<imagecounts; ++i) {
      const imgMain = '/coinrepo/coinholds/images/' + holdId + '/' + i;
      const img480 = '/coinrepo/coinholds/images/small/' + holdId + '/' + i;
      dataSource.push({
        srcSet: img480 + ' 480w, ' +  imgMain + ' 3200w',
        small: img480,
        large: imgMain
      });
    }
    sourceMap.set(holdId, dataSource);
  })
  dispatch(Actions.addImageDataSourceMap(sourceMap));
}