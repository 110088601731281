import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store'
import LatestCoinsPanel from '../features/latestcoins/LatestCoinsPanel';
import SearchedCoinsPanel from '../features/searchedcoins/SearchedCoinsPanel';
import SelectedCountryPanel from '../components/SelectedCountryPanel';
import ExpansionCoinTypeListPanel from './ExpansionCoinTypsListPanel';

const MainPanel: React.FC = () => {
  const countryMenu = useSelector((state: RootState) => state.countryMenu)
  const extendedHold = useSelector((state: RootState) => state.extendedHold)
  const coinTypes = countryMenu.coinTypes;
  const countryPath = countryMenu.countryPath;
  const searchedList = extendedHold.searchedList;
  
  if (coinTypes.length == 0) { 
    return searchedList.length == 0 ? <LatestCoinsPanel /> : <SearchedCoinsPanel />;
  }
  else {
    return (
      <div>
        <SelectedCountryPanel countryPath={countryPath!} />
        <ExpansionCoinTypeListPanel />
      </div>
    );
  }
}

export default MainPanel;