import React, { useState }  from 'react';
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import SearchCoinsCondition from '../../types/SearchCoinsCondition';

const styles = (theme: Theme) : StyleRules => createStyles({
  labelStyle: {
    position: 'relative',
    top: '50%',
    left: '0%',
    transform: 'translateY(-50%)',
  },
  switchStyle: {
    'margin-right':'50px'
  }
});

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  onClickSearch: (condition: SearchCoinsCondition) => void;
  onClickCancel: () => void;
};

const SearchCoinsDialogComponent: React.FC<Props> = ({ classes, open, onClickSearch, onClickCancel }: Props) => {
  const [enabled, setEnabled] = useState({
    enabledCatalogNumber: false,
    enabledCertification: false,
    enabledGrade:         false,
    enabledSupplement:    false,
    enabledValue:         false,
    enabledHold:          false
  });
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = (data:any) => {
    var condition:SearchCoinsCondition = new SearchCoinsCondition();
    if (data.enabledCatalogNumber && data.catalogNumberField.length > 0) {
      condition.catalogNumber = data.catalogNumberField;
    }
    if (data.enabledCertification && data.certificationField.length > 0) {
      condition.certification = data.certificationField;
    }
    if (data.enabledGrade && data.gradeField.length > 0) {
      condition.grade = data.gradeField;
    }
    if (data.enabledSupplement && data.supplementField.length > 0) {
      condition.supplement = data.supplementField;
    }
    if (data.enabledValue) {
      condition.value = data.valueSlider;
    }
    if (data.enabledHold) {
      condition.hold = data.holdChecked;
    }
    onClickSearch(condition);
  }

  const onChangeSwitch = (e:any) : void => {
    setEnabled({...enabled, [e.target.name]:(e.target.checked)})
  }

  return (
    <Dialog open={open} onClose={onClickCancel} >
      <DialogTitle id="dialog-title">Search Coins</DialogTitle>

      <DialogContent>
        <form>
          <Grid container spacing={3}>
          <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Catalog Number</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledCatalogNumber'
                inputProps={{ 'aria-label': 'catalog-number checkbox' }}
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputRef={register}
                id='catalog-number-field'
                name='catalogNumberField'
                label='CatalogNumber'
                placeholder='KM#222 etc.'
                fullWidth={true}
                disabled={enabled.enabledCatalogNumber == false}
              />
            </Grid>

            <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Ceritification</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledCertification'
                inputProps={{ 'aria-label': 'certification checkbox' }}
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputRef={register}
                id='certification-field'
                name='certificationField'
                label='Certification'
                placeholder='PCGS etc.'
                fullWidth={true}
                disabled={enabled.enabledCertification == false}
              />
            </Grid>

            <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Grade</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledGrade'
                inputProps={{ 'aria-label': 'grade checkbox' }}
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputRef={register}
                id='grade-field'
                name='gradeField'
                label='Grade'
                placeholder='XF, AU etc.'
                fullWidth={true}
                disabled={enabled.enabledGrade == false}
              />
            </Grid>

            <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Supplement</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledSupplement'
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                inputRef={register}
                id='supplement-field'
                name='supplementField'
                label='Supplement'
                placeholder='any string'
                fullWidth={true}
                disabled={enabled.enabledSupplement == false}
              />
            </Grid>

            <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Value</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledValue'
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="valueSlider"
                control={control}
                defaultValue={1}
                onChange={([, value]) => value}
                as={<Slider
                  valueLabelDisplay='on'
                  min={1}
                  max={5}
                  step={1}
                  disabled={enabled.enabledValue == false}
                />}
              />
            </Grid>

            <Grid item xs={3}>
              <Paper elevation={0} className={classes.labelStyle}>
                <Typography>Hold</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Switch
                inputRef={register}
                name='enabledHold'
                onChange={onChangeSwitch}
              />
            </Grid>
            <Grid item xs={7}>
              <Checkbox
                color='primary'
                inputRef={register}
                id='hold-field'
                name='holdChecked'
                disabled={enabled.enabledHold == false}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(SearchCoinsDialogComponent);