import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import { Menu as MenuIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { Actions } from './actions';
import { Actions as ExtendedCoinsAction } from '../extendedcoinholds/actions';
import { URL_GET_COIN_TYPES, URL_GET_COUNTRY_LIST } from '../../app/constants';
import { RootState } from '../../app/store'
import CoinType from '../../types/CoinType';

const styles = (theme: Theme) : StyleRules => createStyles({
  icon: {
    width: 60,
    height: 60,
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      width: 48,
      height: 48,
      fontSize: 28,
    }
  },
  menuIcon: {
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      fontSize: 24
    }
  },
  menuItemCountry: {
    'padding-top': '20px',
    'padding-left': '12px',
    'padding-bottom': '20px',
    'font-size': '2rem',
    [theme.breakpoints.up('lg')]: {
      'padding-top': '8px',
      'padding-left': '12px',
      'padding-bottom': '8px',
      'font-size': '1rem',
    }
  },
  menuItemCountrySelectable: {
    'padding-top': '20px',
    'padding-left': '12px',
    'padding-bottom': '20px',
    'font-size': '2rem',
    'font-weight': 'bold',
    [theme.breakpoints.up('lg')]: {
      'padding-top': '8px',
      'padding-left': '12px',
      'padding-bottom': '8px',
      'font-size': '1rem',
    }
  },
  menuItemSubCountry: {
    'padding-top': '20px',
    'padding-left': '30px',
    'padding-bottom': '20px',
    'font-size': '2rem',
    [theme.breakpoints.up('lg')]: {
      'padding-top': '8px',
      'padding-left': '25px',
      'padding-bottom': '8px',
      'font-size': '1rem',
    }
  },
});

type Props = WithStyles<typeof styles>;

const CountryMenu: React.FC<Props> = ({ classes }: Props) => {
  const dispatch = useDispatch();

  const [clickedMenu, setClickedMenu] = useState(null);
  const handleClickedMenu = (event: any) => {
    setClickedMenu(event.currentTarget);
  }
  const handleCloseMenu = (event: any) => {
    setClickedMenu(null);
  }
  const handleSelectCountry = (event: any, country: string, subCountry: string | null) => {
    setClickedMenu(null);
    let countryPath = country;
    let url = URL_GET_COIN_TYPES + '?country=' + country;
    if (subCountry != null) {
      url = url + '&sub_country=' + subCountry;
      countryPath = country + ' / ' + subCountry;
    }
    dispatch(Actions.selectCountry.started({country, subCountry, countryPath}));
    fetch(url)
      .then(response => response.json())
      .then(json => {
        const coinTypes: Array<CoinType> = json.map((elem:any) => CoinType.fromJson(elem))
        dispatch(Actions.selectCountry.done(
          { params:{country: country, subCountry: subCountry, countryPath: countryPath}, 
            result:{coinTypes: coinTypes } }))
        dispatch(ExtendedCoinsAction.resetSearchedCoinHoldList({}));
      })
  }
  
  const open = Boolean(clickedMenu);
  var menuMaxHeight: number = (window.innerHeight || document.documentElement.clientHeight)
  var items: any[] = []; // MenuItem[]
  useEffect(() => {
    fetch(URL_GET_COUNTRY_LIST)
      .then(response => response.json())
      .then(json => dispatch(Actions.receivedCountryList(json)))
  }, []); // 第二引数に空配列を指定することで、初回のみ実行
  var countryList = useSelector((state: RootState) => state.countryList).countryList;
  if (items.length != countryList.length) {
    Object.keys(countryList).forEach( (country) => {
      if (countryList[country].length == 0) {
        items.push(<MenuItem key={country} 
          className={classes.menuItemCountry} 
          onClick={e => handleSelectCountry(e, country, null)}>{country}</MenuItem>);
      }
      else {
        if (countryList[country].find((subCountry: string) => subCountry === '') !== undefined) {
          items.push(<MenuItem key={country} 
            className={classes.menuItemCountrySelectable} 
            onClick={e => handleSelectCountry(e, country, '')}>{country}</MenuItem>);
        }
        else {
          items.push(<MenuItem key={country} className={classes.menuItemCountry}>{country}</MenuItem>);
        }
        countryList[country].forEach((subCountry: string) => {
          if (subCountry !== '') {
            items.push(<MenuItem key={country + '-' + subCountry} 
              onClick={e => handleSelectCountry(e, country, subCountry)}
              className={classes.menuItemSubCountry}
              >{subCountry}</MenuItem>);
          }
        });
      }
    });
  }

  return (
    <div>
        <IconButton 
          color="inherit" 
          className={classes.icon}
          aria-label="Menu"
          aria-owns={open ? 'countries-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClickedMenu} >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Modal
          open={open}
        >
          <Menu
            id="countries-menu"
            anchorPosition={{
              top: 0,
              left: 0,
            }}
            anchorReference='none'
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight:menuMaxHeight,
              },
            }}
          >
            {items}
          </Menu>
        </Modal>
    </div>
  );
}

export default withStyles(styles)(CountryMenu);