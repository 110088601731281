import React, { MouseEvent } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    color: 'inherit',
    'aria-label': 'LinkLatest',
    width: 60,
    height: 60,
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      width: 48,
      height: 48,
      fontSize: 28
    },
  }, 
  homeIcon: {
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      fontSize: 28,
    },
  }
});

interface Props extends WithStyles<typeof styles> {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const LinkLatestButtonComponent: React.FC<Props> = ({ classes, onClick }) => {
  return (
    <IconButton
      className={classes.root}
      onClick={onClick}
    >
      <HomeIcon className={classes.homeIcon} />
    </IconButton>
  )
}

export default withStyles(styles)(LinkLatestButtonComponent);
