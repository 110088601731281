import React, { ChangeEvent, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ENLARGE_IMAGE_WIDTH } from '../../app/constants';
import EnlargeImageFormComponent from './EnlargeImageFormComponent';
import { RootState } from '../../app/store'
import { Actions } from './actions';

type Props = {
  holdId: number
}

const EnlargeImageForm: React.FC<Props> = ({ holdId }: Props) => {
  const dispatch = useDispatch();

  const onSelectWidth = (e: ChangeEvent<{name?:string|undefined, value:any}>, child?: ReactNode) => {
    dispatch(Actions.selectWidth({holdId: holdId, width: e.target.value}));
  }
  
  var imageWidthMap = useSelector((state: RootState) => state.enlargeImage).imageWidthMap;
  var selectedWidth = imageWidthMap.get(holdId);
  if (selectedWidth === undefined) {
    selectedWidth = DEFAULT_ENLARGE_IMAGE_WIDTH;
  }

  return <EnlargeImageFormComponent 
    selectWidth={selectedWidth} 
    onChange={onSelectWidth}
  />
}

export default EnlargeImageForm;