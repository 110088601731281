export default interface ImageDataSource {
  srcSet: string,
  small:  string,
  large:  string
}

export const addDataSource = (holdId:number, array:Array<ImageDataSource>) => {
  var index = array.length;
  var urlLarge = '/coinrepo/coinholds/images/' + holdId + '/' + index;
  var urlSmall = '/coinrepo/coinholds/images/small/' + holdId + '/' + index;
  array.push({ 
    srcSet: urlSmall + ' 480w, ' +  urlLarge + ' 3200w',
    small: urlSmall,
    large: urlLarge
  });
}