import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SingleCoinHoldPanel from './SingleCoinHoldPanel';
import { URL_GET_COIN_HOLDS } from '../app/constants';
import CoinHold from '../types/CoinHold';
import { RootState } from '../app/store'
import { Actions } from '../features/coinholds/actions';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    width: '100%',
   //height: '60px' -> 指定するとExpansion時にも固定されてしまう。
  },
  thumb: {
    flexBasis: '10.0%',
    flexShrink: 0,
    minWidth: '120px',
    minHeight: '90px'
  },
  space: {
    flexBasis: '3.0%',
    flexShrink: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(28),
    flexBasis: '60.0%',
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(20),
      flexBasis: '50.0%',
      flexShrink: 0,
    }
  },
});

interface Props extends WithStyles<typeof styles> {
  holdId: number;
}

const ExpansionCoinHoldPanel: React.FC<Props> = ({ classes, holdId }: Props) => {
  const dispatch = useDispatch();
  const coinHoldMap = useSelector((state: RootState) => state).coinHold.coinHoldMap;
  
  useEffect(() => {
    if (coinHoldMap.get(holdId) === undefined) {
      fetch(URL_GET_COIN_HOLDS + '?id=' + holdId)
        .then(response => response.json())
        .then(json => {
          const coinHold = CoinHold.fromJson(json[0]);
          dispatch(Actions.receivedCoinHold(coinHold));
        });
    }
  }, []);

  const coinHold = coinHoldMap.get(holdId);
  if (coinHold === undefined) {
    return ( <div /> );
  }
  let holdHead = (coinHold.date || '') + ' ';
  if (coinHold.certification != null) {
    holdHead = holdHead + coinHold.certification + ' ';
  }
  holdHead = holdHead + coinHold.grade;

  return (
    <ExpansionPanel className={classes.root} >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <CardMedia className={classes.thumb} image={'/coinrepo/coinholds/images/thumb/' + holdId + '/0'}/>
        <div className={classes.space} />
        <Typography className={classes.heading}>{holdHead}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SingleCoinHoldPanel holdId={holdId} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

 
export default withStyles(styles)(ExpansionCoinHoldPanel);