import React, { useState, useEffect }  from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import CoinType from '../types/CoinType';
import SingleCoinHoldPanel from './SingleCoinHoldPanel';
import ExpansionCoinHoldPanel from './ExpansionCoinHoldPanel';
import { addImageDataSource } from '../features/coinholds/ImageDataSourceHelper';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    width: '100%',
  },
  card: {
    'border-top':'5px',
    'border-bottom': '8px',
    'border-left': '5px',
    'border-right': '8px',
    'border-color': '#667788',
    'margin-bottom': '5px',
    'margin-left': '15px',
    'margin-right': '15px'
  },
  thumb: {
    //flexBasis: '10.0%',
    flexShrink: 0,
    minWidth: '120px',
    minHeight: '90px',
    maxWidth: '120px',
    maxHeight: '90px'
  },
});

interface Props extends WithStyles<typeof styles> {
  coinType: CoinType
}

const ExpansionCoinTypePanel: React.FC<Props> = ({ classes, coinType }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const holdSize = coinType.holdMap.size;
  const dispatch = useDispatch();
  useEffect(() => {
    if (holdSize != 0) {
      addImageDataSource(dispatch, coinType.holdMap);
    }
  }, []);

  if (holdSize == 0) {
    return <div />
  }
  let action;
  let cardContents;
  // title文字列生成
  let title = coinType.denomination + ' \u00A0\u00A0' + 
    (coinType.firstDate || '') + 
    (coinType.firstDate == coinType.lastDate ? '' : (' - '  + coinType.lastDate)) +
    (coinType.catalogNumber !== undefined ? (' \u00A0\u00A0'  + coinType.catalogNumber) : '') +
    ('  (No.' + coinType.id) + ')';
  // subTitle文字列生成
  let subTitle = (coinType.ruler || '');
  let material = coinType.material;
  if (material !== undefined) {
    material += ((coinType.fitness !== undefined && coinType.fitness != 0)
      ? (' ( ' + coinType.fitness + ' )') : '')
    subTitle = (subTitle != '') ? (subTitle + ' / ' + material) : material;
  }
  if (coinType.weight !== undefined && coinType.weight != 0) {
    subTitle = (subTitle != '') ? (subTitle + ' / ' + coinType.weight) : coinType.weight.toString();
    subTitle += ' g'
  }
  const firstHoldId = coinType.holdMap.keys().next().value;

  if (expanded) {
    action = (
      <IconButton onClick={e => setExpanded(false)}>
        <KeyboardArrowUp />
      </IconButton>
    );
    if (holdSize == 1 ) {
      cardContents = <SingleCoinHoldPanel holdId={firstHoldId} />;
    }
    else {
      cardContents = Array.from(coinType.holdMap.keys()).map( (hid:number) => {
        return <ExpansionCoinHoldPanel holdId={hid} />
      });
    }
  }
  else {
    action = (
      <IconButton onClick={e => setExpanded(true)}>
        <KeyboardArrowDown />
      </IconButton>
    );
    cardContents = (
      <div className={classes.root}></div>
    );
  }
  
  return (
    <Card key={title} className={classes.card}>
      <Grid container>
        <Grid item xs={2} >
        <CardMedia className={classes.thumb} 
          image={'/coinrepo/coinholds/images/thumb/' + firstHoldId + '/0'}/>
        </Grid>
        <Grid item xs={9}>
          <Typography variant='h6'>
            {title}
          </Typography>
          <Typography variant='body1'>
            {subTitle}
          </Typography>
          <Typography variant='body2'>
            {coinType.supplement || ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {action}
        </Grid>
      </Grid>
      {cardContents}
    </Card>
  );
}

export default withStyles(styles)(ExpansionCoinTypePanel);