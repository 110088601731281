import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: theme.spacing.length * 1,
    marginBottom: 10
  }),
});

interface Props extends WithStyles<typeof styles> {
  countryPath: string,
}

const SelectedCountryPanel: React.FC<Props> = ({ classes, countryPath }: Props) => {
  return (
    <Paper className={classes.root} elevation={4}>
      <Typography variant='h5'>
        {countryPath}
      </Typography>
    </Paper>
  );
}

export default withStyles(styles)(SelectedCountryPanel);