import React, { useState } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SearchCoinsDialog from '../features/searchcoinsdialog/SearchCoinsDialog';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    color: 'inherit',
    'aria-label': 'ShowSearch',
    width: 60,
    height: 60,
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      width: 48,
      height: 48,
      fontSize: 28
    },
  },
  searchIcon: {
    fontSize: 36,
    [theme.breakpoints.up('lg')]: {
      fontSize: 28,
    },
  }
});

interface Props extends WithStyles<typeof styles> {
}

const SearchCoinsButton: React.FC<Props> = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <IconButton
        className={classes.root}
        onClick={onClick}
      >
        <SearchIcon className={classes.homeIcon} />
      </IconButton>
      <SearchCoinsDialog open={open} onClose={onClose} />
    </div>
  )
}

export default withStyles(styles)(SearchCoinsButton);