import { reducerWithInitialState } from 'typescript-fsa-reducers';
import clonedeep from 'lodash.clonedeep';
import { Actions } from './actions';

export interface EnlargeImageState {
  imageWidthMap: Map<number, number>; // holdId -> width
}

const initialState: EnlargeImageState = {
  imageWidthMap: new Map<number, number>()
}

export const enlargeImageReducer = reducerWithInitialState(initialState)
  .case(Actions.selectWidth, (state: EnlargeImageState, payload) => {
    var newMap = clonedeep(state.imageWidthMap);
    newMap.set(payload.holdId, payload.width);
    return {
      ...state,
      imageWidthMap: newMap
    }
  })