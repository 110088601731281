export default class NormalizedDenomination {

  value: number;
  unit: string;

  constructor(denom:string) {
    var num = 1.0;
    var unit = denom;
    var numMatch = denom.match(/^([0-9\/\-])+/)
    if (numMatch) {
      unit = unit.substr(numMatch[0].length).trim(); // unit確定
      var numStr = numMatch[0]
      var idxHyphen = numStr.indexOf('-')
      if (idxHyphen != -1) {
        num = parseFloat(numStr.substring(0, idxHyphen).trim());
        var decimalArr = numStr.substr(idxHyphen + 1).trim().split('/');
        num = num + (parseFloat(decimalArr[0]) / parseFloat(decimalArr[1]));
      }
      else {
        var idxSlash = numStr.indexOf('/')
        if (idxSlash != -1) {
          var decimalArr = numStr.split('/')
          num = parseFloat(decimalArr[0]) / parseFloat(decimalArr[1]);
        }
        else {
          num = parseFloat(numStr);
        }
      }
    }
    this.value = num;
    this.unit = unit;
  }
};