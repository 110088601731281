import CoinHold from './CoinHold';
import CoinType from './CoinType';

export default class ExtendedCoinHold {
  id: number;
  country: string;
  denomination: string;
  hold: boolean;
  imageCounts: number;
  catalogNumber?: string;
  certification?: string;
  date?: string;
  firstDate?: number;
  fitness?: number;
  grade?: string;
  holdSupplement?: string;
  holdWeight?: number;
  lastDate?: number;
  material?: string;
  mintage?: number;
  ruler?: string;
  subCountry?: string;
  typeId?: number;
  typeSupplement?: string;
  typeWeight?: number;
  value?: number;

  constructor(id:number, country:string, denomination:string, hold:boolean, imageCounts:number) {
    this.id = id;
    this.country = country;
    this.denomination = denomination;
    this.hold = hold;
    this.imageCounts = imageCounts;
  }

  toCoinHold = () : CoinHold => {
    var result: CoinHold = new CoinHold(this.id, this.hold);
    if (this.date           !== undefined) result.date          = this.date;
    if (this.certification  !== undefined) result.certification = this.certification;
    if (this.grade          !== undefined) result.grade         = this.grade;
    if (this.holdSupplement !== undefined) result.supplement    = this.holdSupplement;
    if (this.holdWeight     !== undefined) result.weight        = this.holdWeight;
    if (this.mintage        !== undefined) result.mintage       = this.mintage;
    if (this.value          !== undefined) result.value         = this.value;
    return result;
  }

  toCoinType = () : CoinType => {
    var result: CoinType = new CoinType(this.country, this.denomination);
    if (this.catalogNumber  !== undefined) result.catalogNumber = this.catalogNumber;
    if (this.firstDate      !== undefined) result.firstDate     = this.firstDate;
    if (this.fitness        !== undefined) result.fitness       = this.fitness;
    if (this.lastDate       !== undefined) result.lastDate      = this.lastDate;
    if (this.material       !== undefined) result.material      = this.material;
    if (this.ruler          !== undefined) result.ruler         = this.ruler;
    if (this.subCountry     !== undefined) result.subCountry    = this.subCountry;
    if (this.typeId         !== undefined) result.id            = this.typeId;
    if (this.typeSupplement !== undefined) result.supplement    = this.typeSupplement;
    if (this.typeWeight     !== undefined) result.weight        = this.typeWeight;
    return result;
  }

  static fromJson = (json: any) : ExtendedCoinHold => {
    var result: ExtendedCoinHold = new ExtendedCoinHold( 
      json.holdId, json.country, json.denomination, json.hold, json.imagecounts);
    if (ExtendedCoinHold.isValid(json, 'catalog_number'))  result.catalogNumber  = json['catalog_number'];
    if (ExtendedCoinHold.isValid(json, 'certification'))   result.certification  = json['certification'];
    if (ExtendedCoinHold.isValid(json, 'date'))            result.date           = json['date'];
    if (ExtendedCoinHold.isValid(json, 'first_date'))      result.firstDate      = json['first_date'];
    if (ExtendedCoinHold.isValid(json, 'fitness'))         result.fitness        = json['fitness'];
    if (ExtendedCoinHold.isValid(json, 'grade'))           result.grade          = json['grade'];
    if (ExtendedCoinHold.isValid(json, 'hold_supplement')) result.holdSupplement = json['hold_supplement'];
    if (ExtendedCoinHold.isValid(json, 'hold_weight'))     result.holdWeight     = json['hold_weight'];
    if (ExtendedCoinHold.isValid(json, 'last_date'))       result.lastDate       = json['last_date'];
    if (ExtendedCoinHold.isValid(json, 'material'))        result.material       = json['material'];
    if (ExtendedCoinHold.isValid(json, 'mintage'))         result.mintage        = json['mintage'];
    if (ExtendedCoinHold.isValid(json, 'ruler'))           result.ruler          = json['ruler'];
    if (ExtendedCoinHold.isValid(json, 'sub_country'))     result.subCountry     = json['sub_country'];
    if (ExtendedCoinHold.isValid(json, 'type_id'))         result.typeId         = json['type_id'];
    if (ExtendedCoinHold.isValid(json, 'type_supplement')) result.typeSupplement = json['type_supplement'];
    if (ExtendedCoinHold.isValid(json, 'type_weight'))     result.typeWeight     = json['type_weight'];
    if (ExtendedCoinHold.isValid(json, 'value'))           result.value          = json['value'];
    return result;
  }

  private static isValid = (json:any, propName: string) : boolean => {
    return json[propName] != null && json[propName] != '';
  }
};

