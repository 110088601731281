import React, { ChangeEvent, ReactNode } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    minWidth: '120px',
    'margin-left': '15px'
  }
});

interface Props extends WithStyles<typeof styles> {
  selectWidth: number;
  onChange: (e: ChangeEvent<{name?:string|undefined, value:any}>, child?: ReactNode) => void;
}

const EnlargeImageFormComponent: React.FC<Props> = ({ classes, selectWidth, onChange }: Props) => {

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="image-magnification">Zoom width</InputLabel>
      <Select
        value={selectWidth}
        onChange={onChange}
      >
        <MenuItem value={800}>800</MenuItem>
        <MenuItem value={1600}>1600</MenuItem>
        <MenuItem value={3200}>3200</MenuItem>
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(EnlargeImageFormComponent);