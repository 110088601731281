import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from '../countrymenu/actions';
import { Actions as ExtendedCoinsAction } from '../extendedcoinholds/actions';
import LinkLatestButtonComponent from './LinkLatestButtonComponent';

const LinkLatestButton: React.FC = () => {
  const dispatch = useDispatch();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    dispatch(Actions.resetCountry({}));
    dispatch(ExtendedCoinsAction.resetSearchedCoinHoldList({}));
  }

  return (
    <LinkLatestButtonComponent onClick={onClick} />
  );
}

export default LinkLatestButton;