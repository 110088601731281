import { DEFAULT_SEARCH_MAX_NUMBER } from "../app/constants";

export default class SearchCoinsCondition {
  isOr ?: boolean;
  catalogNumber ?: string;
  certification ?: string;
  grade ?: string;
  supplement ?: string;
  value ?: number;
  hold ?: boolean;

  makeQuery = (): string => {
    let query:string = '';
    if (this.catalogNumber !== undefined) {
      query += ('&catalog_number=' + this.escapeHttp(this.catalogNumber));
    }
    if (this.certification !== undefined) {
      query += ('&certification=' + this.certification);
    }
    if (this.grade !== undefined) {
      query += ('&grade=' + this.escapeHttp(this.grade));
    }
    if (this.supplement !== undefined) {
      query += ('&supplement=' + this.escapeHttp(this.supplement));
    }
    if (this.value !== undefined) {
      query += ('&value=' + this.value);
    }
    if (this.hold !== undefined) {
      query += ('&hold=' + (this.hold ? '1' : '0'));
    }
    query += ('&limit=' + DEFAULT_SEARCH_MAX_NUMBER);
    return query.replace('&', '?'); // 先頭文字だけ置換
  };
  
  private escapeHttp = (s: string) : string => {
    // 文字列中の'#'は'%23'等、URIのエスケープ処理を行う
    return s.replace(/\#/g, '%23')
            .replace(/\$/g, '%24')
            .replace(/\&/g, '%26')
            .replace(/\'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/\*/g, '%2A')
            .replace(/\+/g, '%2B')
            .replace(/\,/g, '%2C')
            .replace(/\:/g, '%3A')
            .replace(/\;/g, '%3B')
            .replace(/\=/g, '%3D')
            .replace(/\?/g, '%3F')
            .replace(/\@/g, '%40')
            .replace(/\[/g, '%5B')
            .replace(/\]/g, '%5D');
  }
};