import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store'
import ExpansionCoinTypePanel from '../components/ExpansionCoinTypePanel';

const ExpansionCoinTypeListPanel: React.FC = () => {
  const coinTypes = useSelector((state: RootState) => state).countryMenu.coinTypes;
  const cards = coinTypes.map( (coinType) => {
    return <ExpansionCoinTypePanel coinType={coinType} key={coinType.id} />
  });

  return (
    <div>
      {cards}
    </div>
  )
}

export default ExpansionCoinTypeListPanel;