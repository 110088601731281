import React from 'react';
import { useSelector } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RootState } from '../app/store'
import { DEFAULT_ENLARGE_IMAGE_WIDTH, EXPANDED_COIN_IMAGE_AREA_ID_PREFIX } from '../app/constants';
import ImageDataSource from '../types/ImageDataSource';
import { EnlargedImageDimension } from '../types/EnlargedImageDimension';

const styles = (theme: Theme) : StyleRules => createStyles({
  holdPanel: {
    'max-width' : '480px',
    'width': '480px',
    'margin-top': '10px',
    'margin-bottom': '30px',
    'margin-left': '30px',
    'margin-right': '30px',
  },
});

const RoyalBlueArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "royalblue" }}
      onClick={onClick}
    />
  );
}

interface Props extends WithStyles<typeof styles> {
  holdId: number;
  dataSourceList: Array<ImageDataSource>;
  imageDimension: EnlargedImageDimension;
}

const CoinHoldImagePanel : React.FC<Props> = 
  ({classes, holdId, dataSourceList, imageDimension}: Props) => {
  const imageWidthMap = useSelector((state: RootState) => state).enlargeImage.imageWidthMap;
  
  const selectWidth = (imageWidthMap.get(holdId) || DEFAULT_ENLARGE_IMAGE_WIDTH);
  const areaId:string = EXPANDED_COIN_IMAGE_AREA_ID_PREFIX + holdId;
  const enlargedImagePosition = 'beside';
  const selectHeight = 2400 * selectWidth / 3200;
  return (
    <div className={classes.holdPanel}>
      <ReactSlick {...{
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <RoyalBlueArrow />,
        nextArrow: <RoyalBlueArrow />
      }}>
        {dataSourceList.map((dataSource, index) => {
          return (<div key={index}>
            <Hidden lgUp>
              <img src={dataSource.small} />
            </Hidden>
            <Hidden mdDown>
              <ReactImageMagnify {...{
                smallImage: {
                  isFluidWidth: true,
                  src: dataSource.small,
                  srcSet: dataSource.srcSet,
                  sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                },
                largeImage:{
                  src: dataSource.large,
                  width: selectWidth,
                  height: selectHeight
                },
                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                isHintEnabled: false,
                shouldHideHintAfterFirstActivation: false,
                enlargedImagePosition: enlargedImagePosition,
                enlargedImagePortalId: areaId,
                isEnlargedImagePortalEnabledForTouch: true,
                enlargedImageContainerDimensions: imageDimension
              }}/>
            </Hidden>
          </div>);
        })}
      </ReactSlick>
    </div>
  );
}

export default withStyles(styles)(CoinHoldImagePanel);