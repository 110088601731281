import React from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import { Variant } from '@material-ui/core/styles/createTypography';
import { EXPANDED_COIN_IMAGE_AREA_ID_PREFIX } from '../app/constants';
import CoinHold from '../types/CoinHold';
import { coinHoldReducer } from '../features/coinholds/reducers';
import TypographizedNumber from './parts/TypographizedNumber';
import TypographizedString from './parts/TypographizedString';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    'margin-top': '10px',
    'margin-left' : '15px'
  },
  zoomImage: {
    'margin-top' : '10px',
    'max-height' : '320px'
  },
  noZoomImage: {
    'padding-left': '10px',
    'padding-bottom': '15px'
  }
});

interface Props extends WithStyles<typeof styles> {
  coinHold: CoinHold;
}

const CoinHoldDetailPanel: React.FC<Props> = ({ classes, coinHold }) => {

  const areaId:string = EXPANDED_COIN_IMAGE_AREA_ID_PREFIX + coinHold.id;
  const typoStyle:Variant = 'body1'

  const splitSupplements = (coinHold.supplement || '').split('\\r\\n').map( str => {
    return <TypographizedString value={str} variant='body2' />
  });
  return (
    <div className={classes.root}>
      <div id={areaId}/>
      <TypographizedNumber 
        value={coinHold.id} prefix='ID: ' variant={typoStyle} />
      <TypographizedString 
        value={coinHold.date} prefix='date: ' variant={typoStyle} />
      <TypographizedString 
        value={coinHold.grade} 
        prefix={"grade: " + (coinHold.certification || '') + ' '}
        variant={typoStyle} />
      <TypographizedNumber 
        value={coinHold.mintage} prefix='mintage: ' variant={typoStyle} />
      <TypographizedString 
        value={coinHold.hold !== undefined && coinHold.hold == true ? '〇' : '×'} 
        prefix='hold: ' variant={typoStyle} />
      <TypographizedNumber 
        value={coinHold.weight} prefix='weight: ' suffix=' g' variant={typoStyle} />
      <TypographizedNumber 
        value={coinHold.value} prefix='value: ' variant={typoStyle} />
      <p />
      {splitSupplements}
    </div>
  );
}

export default withStyles(styles)(CoinHoldDetailPanel);