import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import { RootState } from '../../app/store';
import SingleCoinCardPanel from '../../components/SingleCoinCardPanel';
import ExtendedCoinHold from '../../types/ExtendedCoinHold';
import { DEFAULT_SEARCH_MAX_NUMBER } from '../../app/constants';


const styles = (theme: Theme) : StyleRules => createStyles({
  title: {
    marginTop: '15px',
    marginLeft: '20px',
  },
});

type Props = WithStyles<typeof styles>;

const SearchedCoinsPanel: React.FC<Props> = ({ classes }: Props) => {
  const searchedList = useSelector((state: RootState) => state.extendedHold.searchedList);
  const imageDataSourceMap = useSelector((state: RootState) => state.coinHold.imageDataSourceMap);
  var coinCardPanels = searchedList.map((hold: ExtendedCoinHold) => { 
    var dataSourceList = imageDataSourceMap.get(hold.id);
    if (dataSourceList === undefined) {
      console.log('not found dataSourceList -- holdId:', hold.id)
      return <div />
    }
    return <SingleCoinCardPanel coinHold={hold!} dataSourceList={dataSourceList!} /> // holdはnullではないはず。
  });
  return (
    <div>
      <Typography gutterBottom className={classes.title} variant='h6'>
        検索結果: 上位 {DEFAULT_SEARCH_MAX_NUMBER} 件を表示
      </Typography>
      {coinCardPanels}
    </div>
  );
}

export default withStyles(styles)(SearchedCoinsPanel);