import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Variant } from '@material-ui/core/styles/createTypography';

type Props = {
  value: number|undefined;
  prefix?: string;
  suffix?: string;
  variant: Variant;
}

const TypographizedNumber: React.FC<Props> = ({ value, prefix, suffix, variant }: Props) => {
  if (value === undefined || value == 0) {
    return <Typography />;
  }
  return (<Typography variant={variant}>{
    (prefix || '') + value + (suffix || '') 
  }</Typography>);
}

export default TypographizedNumber;