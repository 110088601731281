import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import CoinTypePanel from './CoinTypePanel';
import ExtendedCoinHold from '../types/ExtendedCoinHold';
import ImageDataSource from '../types/ImageDataSource';
import CoinHoldImagePanel from './CoinHoldImagePanel';
import { EnlargedImageDimension } from '../types/EnlargedImageDimension';
import CoinHoldDetailPanel from './CoinHoldDetailPanel';
import EnlargeImageForm from '../features/enlargeimageform/EnlargeImageForm';

//
// トップページで表示するCoinType, CoinHoldを含む情報のパネル
//

const styles = (theme: Theme) : StyleRules => createStyles({
  card: {
    'border-top':'5px',
    'border-bottom': '8px',
    'border-left': '5px',
    'border-right': '8px',
    'border-color': '#667788',
    'margin-bottom': '25px',
    'margin-left': '15px',
    'margin-right': '15px'
  },
  headerStyle: {
    'font-size': '1.9rem',
    [theme.breakpoints.up('lg')]: {
      'font-size': '1.6rem',
    }
  },
  cardContentStyle: {
    display: 'flex'
  },
  typePanelStyle: {
    'padding-left': '5px',
    'padding-right': '5px',
    'width': '190px',
    'min-width': '190px',
    'max-width': '190px'
  },
  detailPanelStyle: {
    'width': '300px',
    [theme.breakpoints.up('lg')]: {
      'width': '500px',
    }
  },
  zoomForm: {
    minWidth: 120
  }
});

// Component の Props を WithStyles<typeof styles> で拡張
interface Props extends WithStyles<typeof styles> {
  coinHold: ExtendedCoinHold;
  dataSourceList: Array<ImageDataSource>;
}

const SingleCoinCardPanel: React.FC<Props> = ({ classes, coinHold, dataSourceList }: Props) => {  
  const title = coinHold.country + '  ' + 
    (coinHold.subCountry === undefined ? '' : (coinHold.subCountry + '  ')) +
    (coinHold.denomination === undefined ? '' : (coinHold.denomination + '  ')) +
    (coinHold.date === undefined ? '' : (coinHold.date + '  ')) +
    (coinHold.firstDate === undefined ? '' : ('( ' + coinHold.firstDate)) +
    (coinHold.firstDate == coinHold.lastDate ? ' ) ' : (' - '  + coinHold.lastDate + ' ) ')) +
    (coinHold.catalogNumber !== undefined ? (' \u00A0\u00A0'  + coinHold.catalogNumber) : '');

  const imageDimension: EnlargedImageDimension = {
    width: '100%',
    height: '100%'
  }

  return (
    <Card key={title} className={classes.card} >
      <Hidden lgUp>
        <CardHeader
          className={classes.headerStyle}
          title={title} />
      </Hidden>
      <Hidden mdDown>
        <CardHeader
          className={classes.headerStyle}
          title={title}
          action={<EnlargeImageForm holdId={coinHold.id} />}
        />
      </Hidden>
      <div className={classes.cardContentStyle} >
        <div className={classes.typePanelStyle} >
          <CoinTypePanel coinType={coinHold.toCoinType()} />
        </div>
        <CoinHoldImagePanel 
          holdId={coinHold.id} 
          dataSourceList={dataSourceList} 
          imageDimension={imageDimension} />
        <div className={classes.detailPanelStyle} >
          <CoinHoldDetailPanel coinHold={coinHold.toCoinHold()} />
        </div>
      </div>
    </Card>
  )
}

export default withStyles(styles)(SingleCoinCardPanel);