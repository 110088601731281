import { actionCreatorFactory } from 'typescript-fsa';

enum ActionType {
  SELECT_WIDTH = 'SELECT_WIDTH',
}

const actionCreator = actionCreatorFactory('ENLARGE_IMAGE');

export const Actions = {
  selectWidth: actionCreator<{ holdId:number, width:number }>(ActionType.SELECT_WIDTH)
}