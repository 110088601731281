import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import CoinType, { CoinTypeKeys } from '../types/CoinType';
import TypographizedNumber from './parts/TypographizedNumber';
import TypographizedString from './parts/TypographizedString';

const styles = (theme: Theme) : StyleRules => createStyles({
});

interface Props extends WithStyles<typeof styles> {
  coinType: CoinType;
}

const CoinTypePanel : React.FC<Props> = ({ classes, coinType }: Props) => {
  const typoStyle:Variant = 'body1';
  return (
    <CardContent>
      <TypographizedString 
        value={coinType.material} 
        suffix={((coinType.fitness !== undefined && coinType.fitness != 0)
          ? (' ( ' + coinType.fitness + ' )') : '')} variant={typoStyle} />
      <TypographizedNumber 
        value={coinType.weight} prefix='spec weight: ' suffix=' g' variant={typoStyle} />
      <TypographizedString 
        value={coinType.ruler} prefix='ruler: ' variant={typoStyle} />
      <p />
      <TypographizedString 
        value={coinType.supplement} variant={typoStyle} />
    </CardContent>
  );
}

export default withStyles(styles)(CoinTypePanel);