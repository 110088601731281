import { createStore, combineReducers, applyMiddleware } from 'redux';
import { CountryListState, CountryMenuState, countryListReducer, countryMenuReducer } 
  from '../features/countrymenu/reducers';
import { CoinHoldState, coinHoldReducer } from '../features/coinholds/reducers';
import { ExtendedHoldState, extendedHoldReducer } from '../features/extendedcoinholds/reducers';
import { EnlargeImageState, enlargeImageReducer } from '../features/enlargeimageform/reducers';

/// for debug
import logger from 'redux-logger'

export type AppStore = {
  countryList: CountryListState
  countryMenu: CountryMenuState
  extendedHold: ExtendedHoldState
  coinHold: CoinHoldState
  enlargeImage: EnlargeImageState
};

const rootReducer = combineReducers<AppStore>({
  countryList: countryListReducer,
  countryMenu: countryMenuReducer,
  extendedHold: extendedHoldReducer,
  coinHold: coinHoldReducer,
  enlargeImage: enlargeImageReducer
});

// states type
export type RootState = ReturnType<typeof rootReducer>;

const Store = createStore(
  rootReducer,
  applyMiddleware(logger)
);

export default Store;