import { actionCreatorFactory } from 'typescript-fsa';

enum ActionType {
  RECEIVED_COUNTRY_LIST = 'RECEIVED_COUNTRY_LIST',
  SELECT_COUNTRY = 'SELECT_COUNTRY',
  RESET_COUNTRY = 'RESET_COUNTRY'
}

const actionCreator = actionCreatorFactory('COUNTRY_MENU');

//export const SelectCountry = actionCreator<{ country: string, subCountry: string}>('SELECT_COUNTRY');

// countryListはJSON
export const Actions = {
  receivedCountryList: actionCreator<any>(ActionType.RECEIVED_COUNTRY_LIST),
  selectCountry: actionCreator.async<{ country: string, subCountry: string | null, countryPath: string }, 
    { coinTypes: any }>(ActionType.SELECT_COUNTRY),
  resetCountry: actionCreator<{}>(ActionType.RESET_COUNTRY)
}


// cointTypesはJSON
//export const ReceivedCoinTypes = actionCreator<{ coinTypes: any }>('RECEIVED_COIN_TYPES');