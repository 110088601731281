import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import CountryMenu from '../features/countrymenu/CountryMenu';
import LinkLatestButton from '../features/linklatestbutton/LinkLatestButton';
import SearchCoinsButton from './SearchCoinsButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow : 1,
    },
    menuTitle: {
      flex: 1,
      'font-size': '1.8rem',
      [theme.breakpoints.up('lg')]: {
        'font-size': '1.25rem',
      },
      'margin-left': '15px',
      color: 'inherit',
    }
  })
);

type Props = {
  menuTitle?: string;
};

const CatalogAppBar: React.FC<Props> = ({ menuTitle }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
				<Toolbar>
					<CountryMenu />
          <Typography className={classes.menuTitle} >
            Minor Coin Collections
          </Typography>
          <SearchCoinsButton />
          <LinkLatestButton />
				</Toolbar>
			</AppBar>
    </div>
  );
}

export default CatalogAppBar;