export const DEFAULT_ENLARGE_IMAGE_WIDTH:number = 1600; 
export const DEFAULT_SEARCH_MAX_NUMBER:number = 20;
export const EXPANDED_COIN_IMAGE_AREA_ID_PREFIX:string = 'expandedCoinImageArea-';
export const LATEST_COIN_HOLDS_NUMBER:number = 5;
//const ROOT:string = 'http://localhost:80' 
const ROOT:string = ''
export const URL_GET_COIN_HOLDS:string = ROOT + '/coinrepo/coinholds';
export const URL_GET_COIN_TYPES:string = ROOT + '/coinrepo/cointypes';
export const URL_GET_COUNTRY_LIST:string = ROOT + '/coinrepo/cointypes/countries';
export const URL_GET_LATEST_COIN_HOLDS:string = ROOT + '/coinrepo/coinholds/recently';
export const URL_SEARCH_COIN_HOLDS:string = ROOT + '/coinrepo/coinholds/search';
