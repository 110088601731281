import { actionCreatorFactory } from 'typescript-fsa';
import CoinHold from '../../types/CoinHold';
import ImageDataSource from '../../types/ImageDataSource';

enum ActionType {
  RECEIVED_COIN_HOLD = 'RECEIVED_COIN_HOLD',
  ADD_IMAGE_DATA_SOURCE_MAP = 'ADD_IMAGE_DATA_SOURCE_MAP',
}

const actionCreator = actionCreatorFactory('COIN_HOLD');

// holdListはJSON
export const Actions = {
  receivedCoinHold: actionCreator<CoinHold>(ActionType.RECEIVED_COIN_HOLD),
  addImageDataSourceMap: actionCreator<Map<number, Array<ImageDataSource>>>(ActionType.ADD_IMAGE_DATA_SOURCE_MAP),
}