import { reducerWithInitialState } from 'typescript-fsa-reducers';
import clonedeep from 'lodash.clonedeep';
import { Actions } from './actions';
import ExtendedCoinHold from '../../types/ExtendedCoinHold';
import ImageDataSource from '../../types/ImageDataSource';

export interface ExtendedHoldState  {
  latestList: Array<ExtendedCoinHold>
  searchedList: Array<ExtendedCoinHold>
  imageDataSourceMap: Map<number, Array<ImageDataSource>> // latest用
}

const initialState: ExtendedHoldState = {
  latestList: [],
  searchedList: [],
  imageDataSourceMap: new Map<number, Array<ImageDataSource>>()
}

export const extendedHoldReducer = reducerWithInitialState(initialState)
  .case(Actions.receivedLatestCoinHoldList, (state: ExtendedHoldState, payload) => {
    return { 
      ...state, 
      latestList: payload.holdList, 
      imageDataSourceMap: payload.imageDataSourceMap 
    }
  })
  .case(Actions.receivedSearchedCoinHoldList, (state: ExtendedHoldState, payload) => {
    return {
      ...state,
      searchedList: payload.holdList
    }
  })
  .case(Actions.resetSearchedCoinHoldList, (state: ExtendedHoldState) => {
    return {
      ...state, 
      searchedList: []
    }
  });