import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Actions } from './actions';
import CoinType from '../../types/CoinType';

export interface CountryListState  {
  countryList: any
}

const initialListState: CountryListState = {
  countryList: {}
}

export const countryListReducer = reducerWithInitialState(initialListState)
  .case(Actions.receivedCountryList, (state: CountryListState, countryList: any) => {
    return { ...state, countryList: countryList }
  });


export interface CountryMenuState {
  country: string | null
  subCountry: string | null
  countryPath: string | null
  coinTypes: Array<CoinType> // json
}

const initialMenuState: CountryMenuState = {
  country: null,
  subCountry: null,
  countryPath: null,
  coinTypes: new Array<CoinType>()
}

export const countryMenuReducer = reducerWithInitialState(initialMenuState)
  .case(Actions.selectCountry.started, (state: CountryMenuState, payload) => {
    const country = payload.country; 
    const subCountry = payload.subCountry;
    const countryPath = (subCountry != null) ? (country + ' / ' + subCountry) : country;
    return { ...state, 
      country: country, 
      subCountry: subCountry, 
      countryPath: countryPath }
  }).case(Actions.selectCountry.done, (state: CountryMenuState, payload) => {
    return { ...state, 
      coinTypes: payload.result.coinTypes }
  }).case(Actions.resetCountry, (state: CountryMenuState) => {
    return { ...state, 
      country: null, 
      subCountry: null, 
      countryPath: null, 
      coinTypes:new Array<CoinType>()}
  })