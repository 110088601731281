import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URL_SEARCH_COIN_HOLDS } from '../../app/constants';
import SearchCoinsDialogComponent from './SearchCoinsDialogComponent';
import ExtendedCoinHold from '../../types/ExtendedCoinHold';
import { Actions as ExtendedCoinsAction } from '../extendedcoinholds/actions';
import { addImageDataSource } from '../coinholds/ImageDataSourceHelper';
import SearchCoinsCondition from '../../types/SearchCoinsCondition';

type Props = {
  open: boolean,
  onClose: () => void,
};

const SearchCoinsDialog: React.FC<Props> = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();

  const onClickSearch = (condition: SearchCoinsCondition) => {
    fetch(URL_SEARCH_COIN_HOLDS + condition.makeQuery())
      .then(response => response.json())
      .then(json => {
        let holdList: Array<ExtendedCoinHold> = json.map((x: any) => ExtendedCoinHold.fromJson(x));
        let holdMap: Map<number, number> = new Map<number, number>(); // holdId -> imagecounts
        holdList.forEach( hold => { 
          holdMap.set(hold.id, hold.imageCounts);
        });
        dispatch(ExtendedCoinsAction.receivedSearchedCoinHoldList({holdList: holdList}));
        addImageDataSource(dispatch, holdMap);
      });
    onClose();
  }

  const onClickCancel = () => {
    onClose();
  }

  return <SearchCoinsDialogComponent 
    open={open} onClickSearch={onClickSearch} onClickCancel={onClickCancel} />
};

export default SearchCoinsDialog;