import { actionCreatorFactory } from 'typescript-fsa';
import ImageDataSource from '../../types/ImageDataSource';
import ExtendedCoinHold from '../../types/ExtendedCoinHold';

enum ActionType {
  RECEIVED_LATEST_COIN_HOLDS = 'RECEIVED_LATEST_COIN_HOLDS',
  RECEIVED_SEARCHED_COIN_HOLDS = 'RECEIVED_SEARCHED_COIN_HOLDS',
  RESET_SEARCHED_COIN_HOLDS = 'RESET_SEARCHED_COIN_HOLDS',
}

const actionCreator = actionCreatorFactory('LATEST_COINS');

// countryListはJSON
export const Actions = {
  receivedLatestCoinHoldList: actionCreator<{
    holdList:Array<ExtendedCoinHold>, imageDataSourceMap:Map<number, Array<ImageDataSource>>
  }>(ActionType.RECEIVED_LATEST_COIN_HOLDS),
  receivedSearchedCoinHoldList: actionCreator<{
    holdList:Array<ExtendedCoinHold>
  }>(ActionType.RECEIVED_SEARCHED_COIN_HOLDS),
  resetSearchedCoinHoldList: actionCreator<{}>(ActionType.RESET_SEARCHED_COIN_HOLDS),
}