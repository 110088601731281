import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import catalogTheme from '../src/catalogTheme';
import './App.css';
import CatalogAppBar from './components/CatalogAppBar'
import MainPanel from './containers/MainPanel';

const App = () => {
  return (
    <ThemeProvider theme={catalogTheme}>
      <CatalogAppBar />
      <MainPanel />
    </ThemeProvider>
  );
}


export default App;
