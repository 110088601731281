import { reducerWithInitialState } from 'typescript-fsa-reducers';
import clonedeep from 'lodash.clonedeep';
import { Actions } from './actions';
import CoinHold from '../../types/CoinHold';
import ImageDataSource from '../../types/ImageDataSource';

export interface CoinHoldState  {
  coinHoldMap: Map<number, CoinHold>; // keyはholdId
  imageDataSourceMap: Map<number, Array<ImageDataSource>> // keyはholdId
}
const initialState: CoinHoldState = {
  coinHoldMap: new Map<number, CoinHold>(),
  imageDataSourceMap: new Map<number, Array<ImageDataSource>>()
}

export const coinHoldReducer = reducerWithInitialState(initialState)
  .case(Actions.receivedCoinHold, (state: CoinHoldState, coinHold: CoinHold) => {
    let newCoinHoldMap = clonedeep(state.coinHoldMap);
    newCoinHoldMap.set(coinHold.id, coinHold);
    return { ...state, coinHoldMap: newCoinHoldMap }
  }).case(Actions.addImageDataSourceMap, (state: CoinHoldState, addMap: Map<number, Array<ImageDataSource>>) => {
    // deep copy
    let newImageDataSourceMap = clonedeep(state.imageDataSourceMap);
    addMap.forEach( (value:any, key:number) => {
      if (newImageDataSourceMap.get(key) === undefined) {
        newImageDataSourceMap.set(key, value);
      }
    });
    return { ...state, imageDataSourceMap: newImageDataSourceMap }
  });
