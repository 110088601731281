import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { LATEST_COIN_HOLDS_NUMBER, URL_GET_LATEST_COIN_HOLDS } from '../../app/constants';
import { Actions } from '../extendedcoinholds/actions';
import { RootState } from '../../app/store'
import ImageDataSource, { addDataSource } from '../../types/ImageDataSource';
import ExtendedCoinHold from '../../types/ExtendedCoinHold';
import SingleCoinCardPanel from '../../components/SingleCoinCardPanel';

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
      marginTop: '15px',
      marginLeft: '20px',
    },
  })
);

const LatestCoinsPanel: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  useEffect(() => {
    fetch(URL_GET_LATEST_COIN_HOLDS + '?limit=' + LATEST_COIN_HOLDS_NUMBER)
      .then(response => response.json())
      .then(json => {
        var latestHoldList: Array<ExtendedCoinHold> = json.map((x: any) => ExtendedCoinHold.fromJson(x));
        var idsMap: Map<number, Array<ImageDataSource>> = new Map<number, Array<ImageDataSource>>();
        latestHoldList.forEach( hold => { 
          var idsList: Array<ImageDataSource> = [];
          for (let i=0; i<hold.imageCounts; ++i) {
            addDataSource(hold.id, idsList);
          }
          idsMap.set(hold.id, idsList);
        });
        dispatch(Actions.receivedLatestCoinHoldList({
          holdList:latestHoldList, imageDataSourceMap:idsMap}));
      });
  }, []); // 第二引数に空配列を指定することで、初回のみ実行
  
  var { latestList, imageDataSourceMap } = useSelector((state: RootState) => state.extendedHold);
  var coinCardPanels = latestList.map((hold: ExtendedCoinHold) => { 
    var dataSourceList = imageDataSourceMap.get(hold.id);
    return <SingleCoinCardPanel coinHold={hold!} dataSourceList={dataSourceList!} /> // holdはnullではないはず。
  });

  return (
    <div>
      <Typography gutterBottom className={classes.title} variant='h6'>
        新着リスト
      </Typography>
      {coinCardPanels}
    </div>
  );
}

export default LatestCoinsPanel;