export default class CoinHold {
  id: number;
  hold: boolean;
  certification?: string;
  createTime?: Date;
  date?: string;
  grade?: string;
  mintage?: number;
  supplement?: string;
  typeId?: number;
  value?: number;
  weight?: number;

  constructor(id:number, hold:boolean, typeId?:number) {
    this.id = id;
    this.hold = hold;
    this.typeId = typeId;
  }

  static fromJson = (json: any) : CoinHold => {
    var result: CoinHold = new CoinHold(json.id, json.hold);

    if (CoinHold.isValid(json, 'certification')) result.certification = json['certification'];
    if (CoinHold.isValid(json, 'create_time'))   result.createTime    = new Date(json['create_time']);
    if (CoinHold.isValid(json, 'date'))          result.date          = json['date'];
    if (CoinHold.isValid(json, 'grade'))         result.grade         = json['grade'];
    if (CoinHold.isValid(json, 'mintage'))       result.mintage       = json['mintage'];
    if (CoinHold.isValid(json, 'ref_coin_type')) result.typeId        = json['ref_coin_type'];
    if (CoinHold.isValid(json, 'supplement'))    result.supplement    = json['supplement'];
    if (CoinHold.isValid(json, 'value'))         result.value         = json['value'];
    if (CoinHold.isValid(json, 'weight'))        result.weight        = json['weight'];
    return result;
  }

  private static isValid = (json:any, propName: string) : boolean => {
    return json[propName] != null && json[propName] != '';
  }
};