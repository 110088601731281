import NormalizedDenomination from "./NormalizedDenomination";

export default class CoinType {
  country: string;
  denomination: string;
  holdMap:Map<number, number>; // holdId -> imagecounts
  normalizedDenom: NormalizedDenomination;

  catalogNumber?: string;
  firstDate?: number;
  fitness?: number;
  id?: number;
  lastDate?: number;
  material?: string;
  ruler?: string;
  subCountry?: string;
  supplement?: string;
  weight?: number;  

  constructor(country:string, denomination:string) {
    this.country = country;
    this.denomination = denomination;
    this.holdMap = new Map<number, number>();
    this.normalizedDenom = new NormalizedDenomination(denomination);
  }

  static fromJson = (json: any) : CoinType => {
    var result: CoinType = new CoinType(json.country, json.denomination);
    if (CoinType.isValid(json, 'catalog_number')) result.catalogNumber = json['catalog_number'];
    if (CoinType.isValid(json, 'first_date'))     result.firstDate     = json['first_date'];
    if (CoinType.isValid(json, 'fitness'))        result.fitness       = json['fitness'];
    if (CoinType.isValid(json, 'id'))             result.id            = json['id'];
    if (CoinType.isValid(json, 'last_date'))      result.lastDate      = json['last_date'];
    if (CoinType.isValid(json, 'material'))       result.material      = json['material'];
    if (CoinType.isValid(json, 'ruler'))          result.ruler         = json['ruler'];
    if (CoinType.isValid(json, 'sub_country'))    result.subCountry    = json['sub_country'];
    if (CoinType.isValid(json, 'supplement'))     result.supplement    = json['supplement'];
    if (CoinType.isValid(json, 'type_weight'))    result.weight        = json['weight'];
    json['holds'].forEach( (element:any) => {
      result.holdMap.set(element.id, element.imagecounts);
    });
    return result;
  }

  private static isValid = (json:any, propName: string) : boolean => {
    return json[propName] != null && json[propName] != '';
  }
}

export type CoinTypeKeys = keyof(CoinType);

export const sortList = (list:Array<CoinType>) => {
  list.sort( (l:CoinType, r:CoinType) => {
    if (l.normalizedDenom.unit < r.normalizedDenom.unit) return -1;
    if (l.normalizedDenom.unit > r.normalizedDenom.unit) return 1;
    if (l.normalizedDenom.value < r.normalizedDenom.value) return -1;
    if (l.normalizedDenom.value > r.normalizedDenom.value) return 1;
    if ((l.firstDate || -9999) < (r.firstDate || -9999)) return -1;
    if ((l.firstDate || -9999) > (r.firstDate || -9999)) return 1;
    if ((l.catalogNumber || '') < (r.catalogNumber || '')) return -1;
    if ((l.catalogNumber || '') > (r.catalogNumber || '')) return 1;
    return 0;
  });
}