import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, Theme } from '@material-ui/core/styles';
import withStyles, { WithStyles, StyleRules } from '@material-ui/core/styles/withStyles';
import { URL_GET_COIN_HOLDS } from '../app/constants';
import CoinHold from '../types/CoinHold';
import { RootState } from '../app/store'
import { Actions } from '../features/coinholds/actions';
import EnlargeImageForm from '../features/enlargeimageform/EnlargeImageForm';
import CoinHoldImagePanel from './CoinHoldImagePanel';
import CoinHoldDetailPanel from './CoinHoldDetailPanel';

const styles = (theme: Theme) : StyleRules => createStyles({
  root: {
    display: 'flex'
  },
  holdImagePanel: {
    'margin-left' : '10px'
  },
  detailPanel: {
    'width': '500px',
    [theme.breakpoints.up('lg')]: {
      'width': '620px',
    }
  },
  enlargeImageForm: {
    'margin-bottom': '15px'
  },
});

interface Props extends WithStyles<typeof styles> {
  holdId: number;
}

const SingleCoinHoldPanel: React.FC<Props> = ({ classes, holdId }: Props) => {
  const dispatch = useDispatch();
  const coinHoldState = useSelector((state: RootState) => state).coinHold
  const coinHoldMap = coinHoldState.coinHoldMap;
  const dataSourceMap = coinHoldState.imageDataSourceMap;
  
  useEffect(() => {
    if (coinHoldMap.get(holdId) === undefined) {
      fetch(URL_GET_COIN_HOLDS + '?id=' + holdId)
        .then(response => response.json())
        .then(json => {
          const coinHold = CoinHold.fromJson(json[0]);
          dispatch(Actions.receivedCoinHold(coinHold));
        });
    }
  }, []);

  const coinHold = coinHoldMap.get(holdId);
  const dataSourceList = dataSourceMap.get(holdId)
  if (coinHold === undefined || dataSourceList === undefined) {
    return ( <div /> );
  }
  return (
    <div className={classes.root}>
      <div className={classes.holdImagePanel}>
        <CoinHoldImagePanel 
          holdId={holdId} 
          dataSourceList={dataSourceList} 
          imageDimension={{
            width: '120%',
            height: '85%'
          }} />
      </div>
      <div className={classes.detailPanel}>
        <Hidden mdDown>
          <div className={classes.enlargeImageForm} >
            <EnlargeImageForm holdId={holdId} />
          </div>
        </Hidden>
        <CoinHoldDetailPanel coinHold={coinHold} />
      </div>
    </div>
  );
}
 
export default withStyles(styles)(SingleCoinHoldPanel);